import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { ChevronDown } from "react-feather"

const ScrollArrow = ({ children }) => {
  return (
      <div className="scroll-arrow">
          <ChevronDown className="arrow-icon"/>
      </div>
  )}
  
  export default ScrollArrow
