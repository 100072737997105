import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import {
  ArrowRight,
  Map,
  Users,
  DollarSign,
  Globe,
  Briefcase,
} from "react-feather"
import SEO from "../components/seo"
import WorldMap from "../components/worldmap"
import ScrollArrow from "../components/scroll-arrow"

const IndexPage = () => (
  <Layout>
    <SEO title="Help Immigrants Start and Grow Companies." />
    <div id="landing-top">
      <div id="contains-map">
        <div id="map-container">
          <WorldMap />

          <div id="arrow-container">
            <ScrollArrow />
          </div>
        </div>
      </div>

      <div id="hero">
        <div id="left-col">
          <div id="left-col-content">
            <h1 id="landing-title">Immigrants Start and Grow Companies.</h1>
            <h2 className="landing-description">
              Apple, Amazon, Google, and Facebook have a combined market
              valuation of ~ $4 trillion and were all founded by first or second
              generation immigrants — Apple founder Steve Jobs was the son of a
              Syrian immigrant, Amazon CEO Jeff Bezos is a second generation
              Cuban immigrant, Google founder Sergey Brin was born in Russia,
              and Facebook co-founder Eduardo Saverin is a Brazilian native. Not
              to mention Elon Musk — founder of Tesla and SpaceX who immigrated
              from South Africa.
            </h2>
          </div>
        </div>
        <div id="right-col">
          <div id="enabler-actions">
            <AniLink fade to="/founders" duration={0.4}>
              <div className="lp-item-container">
                <div className="lp-item-header">
                  <div className="item-icon-container">
                    <Map className="header-icon" />
                  </div>
                  For Founders
                  <div className="item-arrow">
                    <ArrowRight />
                  </div>
                </div>
                <div className="item-description">
                  <p>
                    Learn what your immigration options are and get the help you
                    need to make your dream a reality, find remote investors,
                    and join our community for mentorship or work.
                  </p>
                </div>
              </div>
            </AniLink>

            {/* <AniLink fade to="/enable-founders" duration={0.4}>
              <div className="lp-item-container">

                <div className="lp-item-header">
                  <div className="item-icon-container">
                    <Users className="header-icon" />
                  </div>
            Enable Founders
              <div className="item-arrow">
                    <ArrowRight />
                  </div>
                </div>
                <div className="item-description">
                  <p>Are you a founder, investor, or worker in the tech industry? Share your knowledge, expertise, network, and resources to help mentor and raise up immigrant founders and would-be founders.</p>
                </div>

              </div>
            </AniLink> */}
            {/* <AniLink fade to="/jobs" duration={1}>
          <div className="item-container">
            
            <div className="item-header">
            <div className="item-icon-container">
            <Briefcase className="header-icon" />
            </div>
             Visa Sponsored Jobs
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
              <p>Share your knowledge and experiences with other founders and would-be founders in the same shoes you were earlier in your journey.</p>
            </div>

          </div>
        </AniLink> */}

            {/* <AniLink fade to="/ecosystems" duration={0.4}>
          <div className="item-container">
            
            <div className="item-header">
            <div className="item-icon-container">
            <Globe className="header-icon" />
            </div>
            View Ecosystems
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
              <p>Share your knowledge and experiences with other founders and would-be founders in the same shoes you were earlier in your journey.</p>
            </div>

          </div>
        </AniLink> */}

            {/* <AniLink fade to="/remote-investors" duration={0.4}>
          <div className="item-container">
            
            <div className="item-header">
            <div className="item-icon-container">
            <DollarSign className="header-icon" />
            </div>
            Raise Funds
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
              <p>Share your knowledge and experiences with other founders and would-be founders in the same shoes you were earlier in your journey.</p>
            </div>

          </div>
        </AniLink> */}

            {/* <AniLink fade to="/interviews" duration={1}>
          <div className="item-container">
            
            <div className="item-header">
            <div className="item-icon-container">
            <Coffee className="header-icon" />
            </div>
            Read Interviews
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
              <p>Share your knowledge and experiences with other founders and would-be founders in the same shoes you were earlier in your journey.</p>
            </div>

          </div>
        </AniLink> */}
          </div>
        </div>
      </div>
    </div>

    <div className="quotes">
      <div className="quote-column">
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            The US has less than 5% of the world's population. Which means if
            the qualities that make someone a great programmer are evenly
            distributed, 95% of great programmers are born outside the US.
            <a href="https://twitter.com/paulg" target="_blank">
              <span className="quoted">
                - Paul Graham, Creator of Y Combinator
              </span>
            </a>
          </div>
        </blockquote>
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            In the digital economy, you hire where the talent is. When you
            restrict immigration, the jobs still get created, just somewhere
            else. And later down the road, when those individuals create the
            next Google, it won’t be here.
            <a href="https://twitter.com/levie" target="_blank">
              {" "}
              <span className="quoted">- Aaron Levie, Box CEO </span>{" "}
            </a>
          </div>
        </blockquote>
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            Immigration has contributed immensely to America’s economic success,
            making it a global leader in tech, and also Google the company it is
            today. Disappointed by today’s proclamation — we’ll continue to
            stand with immigrants and work to expand opportunity for all.
            <a href="https://twitter.com/sundarpichai" target="_blank">
              <span className="quoted">- Sundar Pinchai, Google CEO</span>{" "}
            </a>
          </div>
        </blockquote>
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            A society that curtails the inflow of diverse people, especially
            highly skilled ones essentially ends up losing productivity and a
            subset that is really powerful for them to other countries, leading
            to their prosperity and your decline.
            <a href="https://twitter.com/ziyadbasheer" target="_blank">
              <span className="quoted">
                - Ziyad Basheer, Immigrant Founders Curator
              </span>{" "}
            </a>
          </div>
        </blockquote>
      </div>

      <div className="quote-column">
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            Immigrants have started more than half (50 of 91, or 55%) of
            America’s startup companies valued at $1 billion or more and are key
            members of management or product development teams in more than 80%
            of these companies. Research shows the increasing importance of
            immigrants in cutting-edge companies and the U.S. economy at a time
            when executive branch policies are aimed at restricting immigration
            into the United States.
            <a
              href="https://nfap.com/wp-content/uploads/2018/10/2018-BILLION-DOLLAR-STARTUPS.NFAP-Policy-Brief.2018-1.pdf"
              target="_blank"
            >
              <span className="quoted">
                - National Foundation for American Policy{" "}
              </span>{" "}
            </a>
          </div>
        </blockquote>
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            Immigrants have been awarded 39%, or 33 of 85, of the Nobel Prizes
            won by Americans in Chemistry, Medicine and Physics since 2000.
            <a
              href="https://nfap.com/wp-content/uploads/2017/10/Immigrants-and-Nobel-Prizes-1901-to-2017.NFAP-Policy-Brief.October-20171.pdf"
              target="_blank"
            >
              <span className="quoted">
                - National Foundation for American Policy{" "}
              </span>{" "}
            </a>
          </div>
        </blockquote>
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            it is likely none of the billion-dollar companies with at least one
            immigrant founder would have been created or exist in the United
            States if the foreign-born founder had not been allowed to come to
            America.
            <a href="https://nfap.com" target="_blank">
              <span className="quoted">
                - National Foundation for American Policy
              </span>{" "}
            </a>
          </div>
        </blockquote>
        <blockquote>
          {/* <div className="barber"></div> */}
          <div className="quote-text">
            A striking proportion of America’s entrepreneurial heroes have been
            immigrants or the children of immigrants — Alexander Graham Bell and
            Andrew Carnegie were born in Scotland. Andy Grove and Sergey Brin
            were born, respectively, in Hungary and the Soviet Union.
            <a
              href="https://www.wsj.com/articles/how-to-fix-the-american-growth-machine-1539361093"
              target="_blank"
            >
              <span className="quoted">
                - Alan Greenspan and Adrian Wooldridge{" "}
              </span>{" "}
            </a>
          </div>
        </blockquote>
      </div>
    </div>
  </Layout>
)

export default IndexPage
